import loadable from "@loadable/component";
import TagManager from "react-gtm-module";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import AuthenticationContext from "../components/AuthenticationContext";
import { Loading } from "../components/Loading";

import routes from "./index";
const { pathname } = window.location;

if (process.env.REACT_APP_PUBLIC_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_PUBLIC_GTM_ID,
  };

  if (pathname !== routes.setPassword) {
    TagManager.initialize(tagManagerArgs);
  }
}

const fallback = <Loading />;
const Logout = loadable(() => import("../pages/Logout"), { fallback });
const LoginCallback = loadable(
  () =>
    import("@okta/okta-react").then(({ LoginCallback }) => ({
      default: LoginCallback,
    })),
  {
    fallback,
  }
);
const RootPage = loadable(() => import("../pages/Root"), { fallback });
const RegisterPage = loadable(() => import("../pages/Register"), { fallback });
/*const BigAnimalRegisterPage = loadable(
  () => import("../pages/Register/BigAnimal"),
  { fallback }
);*/
const BigAnimalRegisterPage2 = loadable(
  () => import("../pages/Register/BigAnimal2"),
  { fallback }
);
const EmbeddedRegisterPage = loadable(
  () => import("../pages/Register/Embedded"),
  { fallback }
);
const RegisterCompletePage = loadable(
  () => import("../pages/RegisterComplete"),
  { fallback }
);
const RegisterCompletePageBA = loadable(
  () => import("../pages/RegisterCompleteBA"),
  { fallback }
);
const ProfilePage = loadable(() => import("../pages/Profile"), { fallback });
const EditProfilePage = loadable(() => import("../pages/EditProfile"), {
  fallback,
});
const EditPasswordPage = loadable(() => import("../pages/EditPassword"), {
  fallback,
});
const EditMFAPage = loadable(() => import("../pages/EditMFA"), {
  fallback,
});
const SetupOktaPage = loadable(() => import("../pages/SetupOkta"), {
  fallback,
});
const EnrollOktaPage = loadable(() => import("../pages/EnrollOkta"), {
  fallback,
});
const EnrollActivateOktaPage = loadable(
  () => import("../pages/EnrollActivateOkta"),
  {
    fallback,
  }
);
const SetupEmailPage = loadable(() => import("../pages/SetupEmail"), {
  fallback,
});
const SetupSmsPage = loadable(() => import("../pages/SetupSms"), {
  fallback,
});
const RemoveMFAPage = loadable(() => import("../pages/RemoveMFA"), {
  fallback,
});

const SetPasswordPage = loadable(() => import("../pages/SetPassword"), {
  fallback,
});
const LoginPage = loadable(() => import("../pages/Login"), { fallback });
const ForgotPasswordPage = loadable(() => import("../pages/ForgotPassword"), {
  fallback,
});
const ForgotPasswordCompletePage = loadable(
  () => import("../pages/ForgotPasswordComplete"),
  { fallback }
);

export const Router = () => (
  <BrowserRouter>
    <AuthenticationContext>
      {/* <MainMenu /> */}
      <Switch>
        <Route path={routes.root} exact>
          <RootPage />
        </Route>

        {/* Okta flow paths */}
        <Route path={routes.login}>
          <LoginPage />
        </Route>
        <Route path={routes.logout}>
          <Logout />
        </Route>
        <Route path={routes.loginCallback}>
          <LoginCallback />
        </Route>

        {/* Unauthenticated paths */}
        <Route path={routes.registerBigAnimal} exact>
          <BigAnimalRegisterPage2 />
        </Route>
        <Route path={routes.registerEmbedded} exact>
          <EmbeddedRegisterPage />
        </Route>
        <Route path={routes.register} exact>
          <RegisterPage />
        </Route>
        <Route path={routes.registerInterstitial}>
          <RegisterCompletePage />
        </Route>

        <Route path={routes.registerInterstitialba}>
          <RegisterCompletePageBA />
        </Route>

        <Route path={routes.forgotPassword} exact>
          <ForgotPasswordPage />
        </Route>
        <Route path={routes.forgotPasswordInterstitial}>
          <ForgotPasswordCompletePage />
        </Route>

        <Route path={routes.setPassword}>
          <SetPasswordPage />
        </Route>

        {/* Authenticated paths */}
        <Route path={routes.profile} exact>
          <ProfilePage />
        </Route>
        <Route path={routes.editProfile}>
          <EditProfilePage />
        </Route>
        <Route path={routes.editPassword}>
          <EditPasswordPage />
        </Route>
        <Route path={routes.editMFA}>
          <EditMFAPage />
        </Route>
        <Route path={routes.setupOkta}>
          <SetupOktaPage />
        </Route>
        <Route path={routes.enrollOkta}>
          <EnrollOktaPage />
        </Route>
        <Route path={routes.enrollActivateOkta}>
          <EnrollActivateOktaPage />
        </Route>
        <Route path={routes.setupEmail}>
          <SetupEmailPage />
        </Route>
        <Route path={routes.setupSms}>
          <SetupSmsPage />
        </Route>
        <Route path={routes.removeMFA}>
          <RemoveMFAPage />
        </Route>

        {/* Catchall */}
        <Route path="*">
          <Redirect to={routes.root} />
        </Route>
      </Switch>
    </AuthenticationContext>
  </BrowserRouter>
);
