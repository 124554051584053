import environment from "../environment";

import useQuery from "./useQuery";

const useRedirectionQuery = (key: string) => {
  // query is the source that's trying to log in that we
  // should redirect back to
  const query = useQuery().get(key);
  // the query has to both exist and match host and protocol
  // of some environment.redirectUrls
  let valid = false;
  try {
    valid =
      !!query &&
      environment.redirectUrls.some((urlStr) => {
        const queryTarget = new URL(query);
        // Make sure the valid host is the last thing in the query target
        const re = new RegExp(`${urlStr}$`, "i");
        return queryTarget.host.match(re);
      });
  } catch (error) {
    console.error("Invalid URL");
  }

  if (!valid && query) {
    console.error("Invalid query target defined");
  }

  // I don't want the risk that this is misused so if
  // the query param isn't valid, I don't even return it
  return valid ? query : undefined;
};

export default useRedirectionQuery;
