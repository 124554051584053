import { OktaAuth } from "@okta/okta-auth-js";

import environment from "../environment";

const { clientId, baseUrl, authServer, logoutPath, callbackPath } =
  environment.okta;

export const oktaConfig = {
  baseUrl,
  issuer: baseUrl + authServer,
  clientId,
  scopes: ["openid", "email", "profile"],
  postLogoutRedirectUri: window.location.origin + logoutPath,
  redirectUri: window.location.origin + callbackPath,
  // useClassicEngine: true, // comment out for OIE upgrade
  // authParams: { display: "page" },
  tokenManager: {
    // this must stay in sync with edb.com because they're on the same domain
    // this is sessionStorage because it can get out of sync with the Okta domain's
    // session cookie after browser death, causing infinite looping when trying to
    // reach SAML resources
    storage: "cookie",
  },
};

export const oktaAuth = new OktaAuth(oktaConfig);
