import React from "react";

// eslint-disable-next-line import/order
import ReactDOM from "react-dom";

import "tailwindcss/tailwind.css";
import "./index.css";

import TagManager from "react-gtm-module";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

const tagManagerArgs: TagManager.TagManagerArgs = {
  gtmId: "GTM-5W8M67", // our GTM ID
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
