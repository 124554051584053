import axios, { AxiosResponse } from "axios";

import environment from "../environment";

const client = axios.create({
  baseURL: environment.accountsApi.url,
  timeout: 5000,
});

interface SendLeadRequest {
  [key: string]: string | number | boolean | undefined;
}

const responseBody = (response: AxiosResponse) => response.data;

const postToMarketo = {
  post: (url: string, data: SendLeadRequest) =>
    client
      .post<string>(url, JSON.stringify(data), {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then(responseBody),
};

export const marketoSendLeadClient = {
  postToMarketo: (data: SendLeadRequest): Promise<string> =>
    postToMarketo.post(`/marketo/send-lead`, data),
};
