/* eslint-disable @typescript-eslint/no-non-null-assertion */

const environment = {
  okta: {
    authServer: process.env.REACT_APP_OKTA_AUTH_SERVER!,
    logoutPath: process.env.REACT_APP_OKTA_LOGOUT_PATH!,
    callbackPath: process.env.REACT_APP_OKTA_CALLBACK_PATH!,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID!,
    baseUrl: process.env.REACT_APP_OKTA_BASE_URL!,
  },
  accountsApi: { url: process.env.REACT_APP_ACCOUNTS_API_URL! },
  googleRecaptcha: {
    siteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
  },
  redirectUrls: [
    ...JSON.parse(process.env.REACT_APP_SAFE_REDIRECT_URLS!),
    "enterprisedb.okta.com",
    "enterprisedb.com",
    "biganimal.com",
  ],
  isProd: process.env.REACT_APP_PUBLIC_ENV_NAME === "production",
  maintOn: process.env.REACT_APP_MAINT_ON,
  restrictedEmailFormats: process.env.REACT_APP_RESTRICTED_EMAIL_FORMATS,
  productRegistry: {
    basicAuth: process.env.REACT_APP_PRODUCT_REGISTRY_BASIC_AUTH,
  },
  regIdProgram: {
    thankyouUrl: process.env.REACT_APP_REG_ID_THANKYOU_URL,
  },
};

export default environment;
