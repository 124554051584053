const routes = {
  root: "/accounts",
  login: "/accounts/login",
  logout: "/accounts/logout",
  loginCallback: "/login/callback",

  register: "/accounts/register",
  registerBigAnimal: "/accounts/register/biganimal",
  registerEmbedded: "/accounts/register/embedded",
  registerInterstitial: "/accounts/register/complete",
  registerInterstitialba: "/accounts/register/complete-ba",

  forgotPassword: "/accounts/forgot-password",
  forgotPasswordInterstitial: "/accounts/forgot-password/complete",

  setPassword: "/accounts/set-password",

  profile: "/accounts/profile",
  editProfile: "/accounts/profile/edit",
  editPassword: "/accounts/password/edit",
  editMFA: "/accounts/mfa/setup",
  setupOkta: "/accounts/okta/setup",
  enrollOkta: "/accounts/okta/enroll",
  enrollActivateOkta: "/accounts/okta/enroll-activate",
  setupEmail: "/accounts/email/setup",
  setupSms: "/accounts/sms/setup",
  removeMFA: "/accounts/mfa/remove",
  marketingHome: "https://www.enterprisedb.com",
  help: "https://info.enterprisedb.com/Account-Contact-LP.html",
} as const;

export default routes;
