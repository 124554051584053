import { Security as OktaContext } from "@okta/okta-react";
import { useHistory } from "react-router";

import usePostActionRedirect from "../hooks/usePostActionRedirect";
import routes from "../routes";
import { oktaAuth } from "../services/oktaAuth";

const AuthenticationContext = (props) => {
  const router = useHistory();
  const { postActionRedirect, activatePostActionRedirect } =
    usePostActionRedirect("login");
  return (
    <OktaContext
      oktaAuth={oktaAuth}
      restoreOriginalUri={() => {
        if (postActionRedirect) {
          activatePostActionRedirect();
        } else {
          router.push(routes.root);
        }
      }}
      {...props}
    />
  );
};

export default AuthenticationContext;
