import { useEffect } from "react";

import { useLocalStorageValue } from "@react-hookz/web";

import environment from "../environment";
import { registrationProgramApi } from "../services/datawarehouseClient";
import { marketoSendLeadClient } from "../services/marketoSendLeadClient";
import { oktaAuth } from "../services/oktaAuth";

import useRedirectionQuery from "./useRedirectionQuery";

const postLoginRedirectStorageKey = "LOGIN_REDIRECT_INTENTION";
const postLoginRedirectQueryKey = "fromURI";
const postLogoutRedirectStorageKey = "LOGOUT_REDIRECT_INTENTION";
const postLogoutRedirectQueryKey = "fromURILogout";

const usePostActionRedirect = (action: "login" | "logout") => {
  const actionIsLogin = action === "login";
  const query = useRedirectionQuery(
    actionIsLogin ? postLoginRedirectQueryKey : postLogoutRedirectQueryKey
  );

  const [postActionRedirect, setPostActionRedirect, clearPostActionRedirect] =
    useLocalStorageValue<string | undefined>(
      actionIsLogin ? postLoginRedirectStorageKey : postLogoutRedirectStorageKey
    );

  const activatePostActionRedirect = async () => {
    let hasRegProgramId = false;
    if (postActionRedirect) {
      if (postActionRedirect === environment.regIdProgram.thankyouUrl) {
        const user = await oktaAuth.getUser();
        if (
          typeof user === "object" &&
          Object.keys(user).length > 0 &&
          "email" in user
        ) {
          // First check to see if user already has registration id or not.
          const regProgramData = await registrationProgramApi();
          if (
            typeof regProgramData === "object" &&
            Object.keys(regProgramData).length > 0
          ) {
            hasRegProgramId = true;
          } else {
            // Send to Registration ID Program Request to Marketo.
            await marketoSendLeadClient.postToMarketo({
              email: user.email,
              registrationIdRequest: "Yes",
            });
          }
        }
      }
      if (!hasRegProgramId) {
        clearPostActionRedirect();
        location.assign(postActionRedirect);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (query) {
        setPostActionRedirect(query);

        const isAuthenticated = await oktaAuth.isAuthenticated();
        if (actionIsLogin ? isAuthenticated : !isAuthenticated) {
          activatePostActionRedirect();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    postActionRedirect,
    setPostActionRedirect,
    clearPostActionRedirect,
    activatePostActionRedirect,
  };
};

export default usePostActionRedirect;
